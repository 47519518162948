define('ember-paper/components/paper-card-icon-actions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { Component } = Ember;

  /**
   * @class PaperCardIconActions
   * @extends Ember.Component
   */
  exports.default = Component.extend({
    tagName: 'md-card-icon-actions'
  });
});