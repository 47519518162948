define('ember-composable-helpers/helpers/array', ['exports', 'ember-helper', 'ember-array/utils'], function (exports, _emberHelper, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.array = array;
  function array(params = []) {
    // slice params to avoid mutating the provided params
    return (0, _utils.A)(params.slice());
  }

  exports.default = (0, _emberHelper.helper)(array);
});