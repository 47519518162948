define('ember-paper/components/paper-dialog-content', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  const { Component } = Ember;

  /**
   * @class PaperDialogComponent
   * @extends Ember.Component
   */
  exports.default = Component.extend({
    tagName: 'md-dialog-content',
    classNames: ['md-dialog-content']
  });
});