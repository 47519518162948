define('ember-paper/components/paper-sidenav', ['exports', 'ember-paper/templates/components/paper-sidenav'], function (exports, _paperSidenav) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { Component, computed } = Ember;

  /**
   * @class
   * @extends Ember.Component
   */
  exports.default = Component.extend({
    layout: _paperSidenav.default,
    tagName: '',

    name: 'default',
    position: 'left',
    lockedOpen: 'gt-sm',
    open: false,
    closed: computed.not('open'),
    closeOnClick: true,

    actions: {
      onToggle() {
        this.sendAction('onToggle', ...arguments);
      },
      onBackdropTap() {
        this.sendAction('onToggle', false);
      }
    }
  });
});