define('ember-paper/components/paper-sidenav-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { Component, String: { htmlSafe } } = Ember;

  /**
   * @class PaperSidenavContainer
   * @extends Ember.Component
   */
  exports.default = Component.extend({
    classNames: ['flex', 'layout-row'],
    attributeBindings: ['style'],
    style: htmlSafe('overflow: hidden')
  });
});