define('ember-debug-logger/instance-initializers/debug-logger', ['exports', 'ember-debug-logger/utils/debug-logger'], function (exports, _debugLogger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(instance) {
    // In 1.13, the app instance exposes the registry; in 2.x, it proxies it instead
    let registry = instance.register ? instance : instance.registry;
    let inject = registry.inject || registry.injection;

    registry.register('debug-logger:main', (0, _debugLogger.default)(), { instantiate: false });

    ['route', 'component', 'controller', 'service'].forEach(function (type) {
      inject.call(registry, type, 'debug', 'debug-logger:main');
    });
  }

  exports.default = {
    name: 'debug-logger',
    initialize: initialize
  };
});