define('ember-paper/components/paper-dialog-actions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { Component } = Ember;

  /**
   * @class PaperDialogActions
   * @extends Ember.Component
   */
  exports.default = Component.extend({
    tagName: 'md-dialog-actions'
  });
});