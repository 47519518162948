define('ember-paper/utils/promise-proxies', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.promiseObject = promiseObject;
  exports.promiseArray = promiseArray;

  const { RSVP: { Promise }, ArrayProxy, ObjectProxy, PromiseProxyMixin } = Ember;

  // See http://emberjs.com/api/data/classes/DS.PromiseArray.html
  const PromiseArray = exports.PromiseArray = ArrayProxy.extend(PromiseProxyMixin);
  // See http://emberjs.com/api/data/classes/DS.PromiseObject.html
  const PromiseObject = exports.PromiseObject = ObjectProxy.extend(PromiseProxyMixin);

  function promiseObject(promise, label) {
    return PromiseObject.create({
      promise: Promise.resolve(promise, label)
    });
  }

  function promiseArray(promise, label) {
    return PromiseArray.create({
      promise: Promise.resolve(promise, label)
    });
  }
});