define('ember-composable-helpers/helpers/chunk', ['exports', 'ember-array/utils', 'ember-computed', 'ember-helper', 'ember-metal/get', 'ember-metal/observer', 'ember-metal/set'], function (exports, _utils, _emberComputed, _emberHelper, _get, _observer, _set) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.chunk = chunk;


  const { max, ceil } = Math;

  function chunk(num, array) {
    let integer = parseInt(num, 10);
    let size = max(integer, 0);

    let length = 0;
    if ((0, _utils.isEmberArray)(array)) {
      length = (0, _get.default)(array, 'length');
    }

    if (!length || size < 1) {
      return [];
    } else {
      let index = 0;
      let resultIndex = -1;
      let result = new Array(ceil(length / size));

      while (index < length) {
        result[++resultIndex] = array.slice(index, index += size);
      }

      return result;
    }
  }

  exports.default = _emberHelper.default.extend({
    content: (0, _emberComputed.default)('num', 'array.[]', function () {
      let array = (0, _get.default)(this, 'array');
      let num = (0, _get.default)(this, 'num');

      return chunk(num, array);
    }),

    compute([num, array]) {
      (0, _set.default)(this, 'array', array);
      (0, _set.default)(this, 'num', num);

      return (0, _get.default)(this, 'content');
    },

    contentDidChange: (0, _observer.default)('content', function () {
      this.recompute();
    })
  });
});