define('ember-composable-helpers/helpers/filter-by', ['exports', 'ember-array/utils', 'ember-computed', 'ember-helper', 'ember-metal/get', 'ember-metal/observer', 'ember-metal/set', 'ember-utils', 'ember-composable-helpers/utils/is-equal'], function (exports, _utils, _emberComputed, _emberHelper, _get, _observer, _set, _emberUtils, _isEqual) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { defineProperty } = Ember;

  exports.default = _emberHelper.default.extend({
    compute([byPath, value, array]) {
      if (!(0, _utils.isEmberArray)(array) && (0, _utils.isEmberArray)(value)) {
        array = value;
        value = undefined;
      }

      (0, _set.default)(this, 'array', array);
      (0, _set.default)(this, 'byPath', byPath);
      (0, _set.default)(this, 'value', value);

      return (0, _get.default)(this, 'content');
    },

    byPathDidChange: (0, _observer.default)('byPath', 'value', function () {
      let byPath = (0, _get.default)(this, 'byPath');
      let value = (0, _get.default)(this, 'value');

      if ((0, _emberUtils.isEmpty)(byPath)) {
        defineProperty(this, 'content', []);
        return;
      }

      let filterFn;

      if ((0, _emberUtils.isPresent)(value)) {
        if (typeof value === 'function') {
          filterFn = item => value((0, _get.default)(item, byPath));
        } else {
          filterFn = item => (0, _isEqual.default)((0, _get.default)(item, byPath), value);
        }
      } else {
        filterFn = item => !!(0, _get.default)(item, byPath);
      }

      let cp = (0, _emberComputed.filter)(`array.@each.${byPath}`, filterFn);

      defineProperty(this, 'content', cp);
    }),

    contentDidChange: (0, _observer.default)('content', function () {
      this.recompute();
    })
  });
});