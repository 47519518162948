define('ember-composable-helpers/helpers/map', ['exports', 'ember-computed', 'ember-helper', 'ember-metal/get', 'ember-metal/observer', 'ember-metal/set', 'ember-utils'], function (exports, _emberComputed, _emberHelper, _get, _observer, _set, _emberUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { defineProperty } = Ember;

  exports.default = _emberHelper.default.extend({
    compute([callback, array]) {
      (0, _set.default)(this, 'array', array);
      (0, _set.default)(this, 'callback', callback);

      return (0, _get.default)(this, 'content');
    },

    byPathDidChange: (0, _observer.default)('callback', function () {
      let callback = (0, _get.default)(this, 'callback');

      if ((0, _emberUtils.isEmpty)(callback)) {
        defineProperty(this, 'content', []);
        return;
      }

      defineProperty(this, 'content', (0, _emberComputed.map)('array', callback));
    }),

    contentDidChange: (0, _observer.default)('content', function () {
      this.recompute();
    })
  });
});