define('ember-composable-helpers/helpers/invoke', ['exports', 'ember-array/utils', 'ember-helper', 'ember-utils'], function (exports, _utils, _emberHelper, _emberUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.invoke = invoke;
  var RSVP = Ember.RSVP;


  const { all } = RSVP;

  function invoke([methodName, ...args]) {
    let obj = args.pop();

    if ((0, _utils.isEmberArray)(obj)) {
      return function () {
        let promises = obj.map(item => (0, _emberUtils.tryInvoke)(item, methodName, args));

        return all(promises);
      };
    }

    return function () {
      return (0, _emberUtils.tryInvoke)(obj, methodName, args);
    };
  }

  exports.default = (0, _emberHelper.helper)(invoke);
});