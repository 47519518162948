define('ember-composable-helpers/helpers/flatten', ['exports', 'ember-helper', 'ember-array/utils', 'ember-metal/observer', 'ember-metal/set'], function (exports, _emberHelper, _utils, _observer, _set) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.flatten = flatten;
  function flatten(array) {
    if (!(0, _utils.isEmberArray)(array)) {
      return array;
    }

    return array.reduce((flattened, el) => {
      return flattened.concat(flatten(el));
    }, []);
  }

  exports.default = _emberHelper.default.extend({
    compute([array]) {
      (0, _set.default)(this, 'array', array);

      return flatten(array);
    },

    arrayContentDidChange: (0, _observer.default)('array.[]', function () {
      this.recompute();
    })
  });
});