define('ember-composable-helpers/helpers/slice', ['exports', 'ember-helper', 'ember-metal/observer', 'ember-metal/set'], function (exports, _emberHelper, _observer, _set) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberHelper.default.extend({
    compute([start, end, array]) {
      (0, _set.default)(this, 'array', array);
      return array.slice(start, end);
    },

    arrayContentDidChange: (0, _observer.default)('array.[]', function () {
      this.recompute();
    })
  });
});