define('ember-paper/components/paper-toolbar-tools', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { Component } = Ember;

  /**
   * @class PaperToolbarTools
   * @extends Ember.Component
   */
  exports.default = Component.extend({
    classNames: ['md-toolbar-tools']
  });
});