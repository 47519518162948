define('ember-composable-helpers/-private/create-needle-haystack-helper', ['exports', 'ember-computed', 'ember-helper', 'ember-metal/get', 'ember-metal/observer', 'ember-metal/set'], function (exports, _emberComputed, _emberHelper, _get, _observer, _set) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = createNeedleHaystackHelper;


  const {
    isEmpty
  } = Ember;
  const K = () => {};

  /**
   * Creates a generic Helper class implementation that expects a `needle` and
   * `haystack` as arguments. A `fn` function is required to be passed in
   * that is invoked with the `needle` and `haystack` arguments.
   *
   * @private
   * @param  {Function} fn A function to run against the needle and haystack
   * @return {Any}
   */
  function createNeedleHaystackHelper(fn = K) {
    return _emberHelper.default.extend({
      content: (0, _emberComputed.default)('needle.[]', 'haystack.[]', 'option', function () {
        let needle = (0, _get.default)(this, 'needle');
        let haystack = (0, _get.default)(this, 'haystack');
        let option = (0, _get.default)(this, 'option');

        return fn(needle, haystack, option);
      }).readOnly(),

      compute([needle, option, haystack]) {
        if (isEmpty(haystack)) {
          haystack = option;
          option = null;
        }

        (0, _set.default)(this, 'needle', needle);
        (0, _set.default)(this, 'haystack', haystack);
        (0, _set.default)(this, 'option', option);

        return (0, _get.default)(this, 'content');
      },

      contentDidChange: (0, _observer.default)('content', function () {
        this.recompute();
      })
    });
  }
});