define('ember-composable-helpers/helpers/sort-by', ['exports', 'ember-array/utils', 'ember-computed', 'ember-helper', 'ember-metal/get', 'ember-metal/observer', 'ember-metal/set', 'ember-utils'], function (exports, _utils, _emberComputed, _emberHelper, _get, _observer, _set, _emberUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { defineProperty } = Ember;

  exports.default = _emberHelper.default.extend({
    compute(params) {
      // slice params to avoid mutating the provided params
      let sortProps = params.slice();
      let array = sortProps.pop();
      let [firstSortProp] = sortProps;

      if ((0, _emberUtils.typeOf)(firstSortProp) === 'function' || (0, _utils.isEmberArray)(firstSortProp)) {
        sortProps = firstSortProp;
      }

      (0, _set.default)(this, 'array', array);
      (0, _set.default)(this, 'sortProps', sortProps);

      return (0, _get.default)(this, 'content');
    },

    sortPropsDidChange: (0, _observer.default)('sortProps', function () {
      let sortProps = (0, _get.default)(this, 'sortProps');

      if ((0, _emberUtils.isEmpty)(sortProps)) {
        defineProperty(this, 'content', []);
      }

      if (typeof sortProps === 'function') {
        defineProperty(this, 'content', (0, _emberComputed.sort)('array', sortProps));
      } else {
        defineProperty(this, 'content', (0, _emberComputed.sort)('array', 'sortProps'));
      }
    }),

    contentDidChange: (0, _observer.default)('content', function () {
      this.recompute();
    })
  });
});