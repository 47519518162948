define('ember-paper/mixins/parent-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { Mixin, computed, A } = Ember;

  /**
   * @class ParentMixin
   * @extends Ember.Mixin
   */
  exports.default = Mixin.create({
    childComponents: computed(function () {
      return A();
    }),

    register(child) {
      this.get('childComponents').pushObject(child);
    },

    unregister(child) {
      this.get('childComponents').removeObject(child);
    }
  });
});