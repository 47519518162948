define("liquid-fire/dsl", ["exports", "liquid-fire/animate", "liquid-fire/rule", "liquid-fire/constraint", "liquid-fire/action"], function (exports, _animate, _rule, _constraint, _action) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  class DSL {

    constructor(map, constraints) {
      this.map = map;
      this.constraints = constraints;
    }

    setDefault(props) {
      (0, _animate.setDefaults)(props);
    }

    transition() {
      var rule = new _rule.default();
      var parts = Array.prototype.slice.apply(arguments).reduce(function (a, b) {
        return a.concat(b);
      }, []);

      for (var i = 0; i < parts.length; i++) {
        rule.add(parts[i]);
      }

      rule.validate(this.map);
      this.constraints.addRule(rule);
    }

    fromRoute(routeName) {
      return [new _constraint.default('oldRoute', routeName)];
    }

    toRoute(routeName) {
      return [new _constraint.default('newRoute', routeName)];
    }

    withinRoute(routeName) {
      return this.fromRoute(routeName).concat(this.toRoute(routeName));
    }

    fromValue(matcher) {
      return [new _constraint.default('oldValue', matcher)];
    }

    toValue(matcher) {
      return [new _constraint.default('newValue', matcher)];
    }

    betweenValues(matcher) {
      return this.fromValue(matcher).concat(this.toValue(matcher));
    }

    fromModel(matcher) {
      return [new _constraint.default('oldModel', matcher)];
    }

    toModel(matcher) {
      return [new _constraint.default('newModel', matcher)];
    }

    betweenModels(matcher) {
      return this.fromModel(matcher).concat(this.toModel(matcher));
    }

    hasClass(name) {
      return new _constraint.default('parentElementClass', name);
    }

    matchSelector(selector) {
      return new _constraint.default('parentElement', function (elt) {
        return elt.is(selector);
      });
    }

    childOf(selector) {
      return this.matchSelector(selector + ' > *');
    }

    use(nameOrHandler, ...args) {
      return new _action.default(nameOrHandler, args);
    }

    reverse(nameOrHandler, ...args) {
      return new _action.default(nameOrHandler, args, { reversed: true });
    }

    useAndReverse(nameOrHandler, ...args) {
      return [this.use(nameOrHandler, ...args), this.reverse(nameOrHandler, ...args)];
    }

    onInitialRender() {
      return new _constraint.default('firstTime', 'yes');
    }

    includingInitialRender() {
      return new _constraint.default('firstTime', ['yes', 'no']);
    }

    inHelper(...names) {
      return new _constraint.default('helperName', names);
    }

    outletName(...names) {
      return new _constraint.default('outletName', names);
    }

    media(query) {
      return new _constraint.default('media', function () {
        return window.matchMedia(query).matches;
      });
    }

    debug() {
      return 'debug';
    }
  }
  exports.default = DSL;
});