define('ember-debug-logger/index', ['exports', 'ember-debug-logger/utils/debug-logger'], function (exports, _debugLogger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _debugLogger.default;
    }
  });
});