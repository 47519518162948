define('ember-composable-helpers/helpers/toggle', ['exports', 'ember-helper', 'ember-metal/get', 'ember-metal/set', 'ember-utils'], function (exports, _emberHelper, _get, _set, _emberUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.toggle = toggle;


  function nextIndex(length, currentIdx) {
    if (currentIdx === -1 || currentIdx + 1 === length) {
      return 0;
    }

    return currentIdx + 1;
  }

  function toggle([prop, obj, ...values]) {
    return function () {
      let currentValue = (0, _get.default)(obj, prop);

      if ((0, _emberUtils.isPresent)(values)) {
        let currentIdx = values.indexOf(currentValue);
        let nextIdx = nextIndex((0, _get.default)(values, 'length'), currentIdx);

        return (0, _set.default)(obj, prop, values[nextIdx]);
      }

      return (0, _set.default)(obj, prop, !currentValue);
    };
  }

  exports.default = (0, _emberHelper.helper)(toggle);
});