define('ember-paper/components/paper-card-header', ['exports', 'ember-paper/templates/components/paper-card-header'], function (exports, _paperCardHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { Component } = Ember;

  /**
   * @class PaperCardHeader
   * @extends Ember.Component
   */
  exports.default = Component.extend({
    layout: _paperCardHeader.default,
    tagName: 'md-card-header'
  });
});