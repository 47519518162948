define('ember-paper/components/paper-menu-item', ['exports', 'ember-paper/templates/components/paper-menu-item', 'ember-paper/mixins/child-mixin'], function (exports, _paperMenuItem, _childMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { Component } = Ember;

  /**
   * @class PaperMenuItem
   * @extends Ember.Component
   * @uses ChildMixin
   */
  exports.default = Component.extend(_childMixin.default, {
    layout: _paperMenuItem.default,
    tagName: 'md-menu-item',
    disabled: false,

    actions: {
      handleClick(event) {
        this.get('dropdown.actions').close();
        this.sendAction('onClick', event);
      }
    },
    mouseEnter() {
      if (!this.get('disabled')) {
        this.$('button').focus();
      }
    }

  });
});