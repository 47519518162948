define('ember-composable-helpers/-private/create-multi-array-helper', ['exports', 'ember-array/utils', 'ember-helper', 'ember-metal/utils', 'ember-metal/observer', 'ember-metal/get', 'ember-metal/set', 'ember-utils'], function (exports, _utils, _emberHelper, _utils2, _observer, _get, _set, _emberUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (multiArrayComputed) {
    return _emberHelper.default.extend({
      compute([...arrays]) {
        (0, _set.default)(this, 'arrays', arrays.map(obj => {
          if ((0, _utils.isEmberArray)(obj)) {
            return (0, _utils.A)(obj);
          }

          return obj;
        }));

        return (0, _get.default)(this, 'content');
      },

      valuesDidChange: (0, _observer.default)('arrays.[]', function () {
        this._recomputeArrayKeys();

        let arrays = (0, _get.default)(this, 'arrays');
        let arrayKeys = (0, _get.default)(this, 'arrayKeys');

        if ((0, _emberUtils.isEmpty)(arrays)) {
          defineProperty(this, 'content', []);
          return;
        }

        defineProperty(this, 'content', multiArrayComputed(...arrayKeys));
      }),

      contentDidChange: (0, _observer.default)('content.[]', function () {
        this.recompute();
      }),

      _recomputeArrayKeys() {
        let arrays = (0, _get.default)(this, 'arrays');

        let oldArrayKeys = (0, _get.default)(this, 'arrayKeys') || [];
        let newArrayKeys = arrays.map(idForArray);

        let keysToRemove = oldArrayKeys.filter(key => {
          return newArrayKeys.indexOf(key) === -1;
        });

        keysToRemove.forEach(key => (0, _set.default)(this, key, null));
        arrays.forEach(array => (0, _set.default)(this, idForArray(array), array));

        (0, _set.default)(this, 'arrayKeys', newArrayKeys);
      }
    });
  };

  const { defineProperty } = Ember;
  const idForArray = array => `__array-${(0, _utils2.guidFor)(array)}`;
});