define('ember-moment/helpers/moment-from-now', ['exports', 'ember-moment/utils/helper-compute', 'ember-moment/helpers/-base'], function (exports, _helperCompute, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    moment: Ember.inject.service(),

    globalAllowEmpty: false,

    compute: (0, _helperCompute.default)(function (params, { hideSuffix, locale, timeZone }) {
      this._super(...arguments);

      const moment = this.get('moment');

      return this.morphMoment(moment.moment(...params), { locale, timeZone }).fromNow(hideSuffix);
    })
  });
});