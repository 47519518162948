define('ember-concurrency/-wait-for', ['exports', 'ember-concurrency/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.waitForQueue = waitForQueue;
  exports.waitForEvent = waitForEvent;
  var schedule = Ember.run.schedule;


  class WaitForQueueYieldable {
    constructor(queueName) {
      this.queueName = queueName;
    }

    [_utils.yieldableSymbol](taskInstance, resumeIndex) {
      schedule(this.queueName, () => {
        taskInstance.proceed(resumeIndex, _utils.YIELDABLE_CONTINUE, null);
      });
    }
  }

  class WaitForEventYieldable {
    constructor(object, eventName) {
      this.object = object;
      this.eventName = eventName;
    }

    [_utils.yieldableSymbol](taskInstance, resumeIndex) {
      let fn = event => {
        taskInstance.proceed(resumeIndex, _utils.YIELDABLE_CONTINUE, event);
      };
      this.object.one(this.eventName, fn);
      return () => {
        this.object.off(this.eventName, fn);
      };
    }
  }

  /**
   * Use `waitForQueue` to pause the task until a certain run loop queue is reached.
   *
   * ```js
   * import { task, timeout, waitForQueue } from 'ember-concurrency';
   * export default Component.extend({
   *   myTask: task(function * () {
   *     yield waitForQueue('afterRender');
   *     console.log("now we're in the afterRender queue");
   *   })
   * });
   * ```
   *
   * @param {string} queueName the name of the Ember run loop queue
   */
  function waitForQueue(queueName) {
    return new WaitForQueueYieldable(queueName);
  }

  /**
   * Use `waitForEvent` to pause the task until an event is fired. The event
   * can either be a jQuery event or an Ember.Evented event (or any event system
   * where the object supports `.on()` `.one()` and `.off()`).
   *
   * ```js
   * import { task, timeout, waitForEvent } from 'ember-concurrency';
   * export default Component.extend({
   *   myTask: task(function * () {
   *     console.log("Please click anywhere..");
   *     let clickEvent = yield waitForEvent($('body'), 'click');
   *     console.log("Got event", clickEvent);
   *
   *     let emberEvent = yield waitForEvent(this, 'foo');
   *     console.log("Got foo event", emberEvent);
   *
   *     // somewhere else: component.trigger('foo', { value: 123 });
   *   })
   * });
   * ```
   *
   * @param {object} object the Ember Object or jQuery selector (with ,on(), .one(), and .off())
   *                 that the event fires from
   * @param {function} eventName the name of the event to wait for
   */
  function waitForEvent(object, eventName) {
    (true && !((0, _utils.isEventedObject)(object)) && Ember.assert(`${object} must include Ember.Evented (or support \`.on()\`, \`.one()\`, and \`.off()\`) to be able to use \`waitForEvent\``, (0, _utils.isEventedObject)(object)));

    return new WaitForEventYieldable(object, eventName);
  }
});