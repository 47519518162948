define('ember-power-select/helpers/ember-power-select-is-selected', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.emberPowerSelectIsSelected = emberPowerSelectIsSelected;
  var helper = Ember.Helper.helper;
  var isEmberArray = Ember.isArray;
  var isEqual = Ember.isEqual;


  // TODO: Make it private or scoped to the component
  function emberPowerSelectIsSelected([option, selected] /* , hash*/) {
    if (selected === undefined || selected === null) {
      return false;
    }
    if (isEmberArray(selected)) {
      for (let i = 0; i < selected.length; i++) {
        if (isEqual(selected[i], option)) {
          return true;
        }
      }
      return false;
    } else {
      return isEqual(option, selected);
    }
  }

  exports.default = helper(emberPowerSelectIsSelected);
});