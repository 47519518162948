define('ember-composable-helpers/helpers/object-at', ['exports', 'ember-helper', 'ember-array/utils', 'ember-computed', 'ember-metal/observer', 'ember-metal/get', 'ember-metal/set'], function (exports, _emberHelper, _utils, _emberComputed, _observer, _get, _set) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.objectAt = objectAt;
  function objectAt(index, array) {
    if (!(0, _utils.isEmberArray)(array)) {
      return undefined;
    }

    index = parseInt(index, 10);

    return (0, _utils.A)(array).objectAt(index);
  }

  exports.default = _emberHelper.default.extend({
    content: (0, _emberComputed.default)('index', 'array.[]', function () {
      let index = (0, _get.default)(this, 'index');
      let array = (0, _get.default)(this, 'array');

      return objectAt(index, array);
    }),

    compute([index, array]) {
      (0, _set.default)(this, 'index', index);
      (0, _set.default)(this, 'array', array);

      return (0, _get.default)(this, 'content');
    },

    contentDidChange: (0, _observer.default)('content', function () {
      this.recompute();
    })
  });
});