define('ember-macro-helpers/raw', ['exports', 'ember-computed'], function (exports, _emberComputed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (key) {
    return (0, _emberComputed.default)(() => key).readOnly();
  };
});