define('ember-paper/components/paper-card-image', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { Component } = Ember;

  /**
   * @class PaperCardImage
   * @extends Ember.Component
   */
  exports.default = Component.extend({
    tagName: 'img',
    classNames: ['md-card-image'],
    attributeBindings: ['src', 'title', 'alt']
  });
});