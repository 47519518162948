define('ember-composable-helpers/helpers/compact', ['exports', 'ember-array/utils', 'ember-computed', 'ember-helper', 'ember-metal/get', 'ember-metal/observer', 'ember-metal/set', 'ember-utils'], function (exports, _utils, _emberComputed, _emberHelper, _get, _observer, _set, _emberUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberHelper.default.extend({
    compute([array]) {
      if (!(0, _utils.isEmberArray)(array)) {
        return (0, _utils.A)([array]);
      }

      (0, _set.default)(this, 'array', array);

      return (0, _get.default)(this, 'content');
    },

    content: (0, _emberComputed.filter)('array', _emberUtils.isPresent),

    contentDidChange: (0, _observer.default)('content', function () {
      this.recompute();
    })
  });
});