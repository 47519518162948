define('ember-composable-helpers/helpers/append', ['exports', 'ember-computed', 'ember-metal/get', 'ember-array/utils', 'ember-composable-helpers/-private/create-multi-array-helper'], function (exports, _emberComputed, _get, _utils, _createMultiArrayHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.append = append;
  function append(...dependentKeys) {
    dependentKeys = dependentKeys || [];
    let arrayKeys = dependentKeys.map(dependentKey => {
      return `${dependentKey}.[]`;
    });

    return (0, _emberComputed.default)(...arrayKeys, function () {
      let array = dependentKeys.map(dependentKey => {
        let value = (0, _get.default)(this, dependentKey);
        return (0, _utils.isEmberArray)(value) ? value.toArray() : [value];
      });

      return [].concat(...array);
    });
  }

  exports.default = (0, _createMultiArrayHelper.default)(append);
});