define('ember-composable-helpers/helpers/without', ['exports', 'ember-array/utils', 'ember-metal/get', 'ember-utils', 'ember-composable-helpers/-private/create-needle-haystack-helper', 'ember-composable-helpers/utils/includes'], function (exports, _utils, _get, _emberUtils, _createNeedleHaystackHelper, _includes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.without = without;


  function contains(needle, haystack) {
    return (0, _includes.default)((0, _utils.A)(haystack), needle);
  }

  function without(needle, haystack) {
    if (!(0, _utils.isEmberArray)(haystack)) {
      return false;
    }

    if ((0, _emberUtils.typeOf)(needle) === 'array' && (0, _get.default)(needle, 'length')) {
      return haystack.reduce((acc, val) => contains(val, needle) ? acc : acc.concat(val), []);
    }

    return (0, _utils.A)(haystack).without(needle);
  }

  exports.default = (0, _createNeedleHaystackHelper.default)(without);
});