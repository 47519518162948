define('ember-paper/helpers/underscore', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.underscore = underscore;


  const { Helper, String: Str } = Ember;

  function underscore([text]) {
    return Str.underscore(text);
  }

  exports.default = Helper.helper(underscore);
});