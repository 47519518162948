define('ember-composable-helpers/helpers/group-by', ['exports', 'ember-array/utils', 'ember-computed', 'ember-helper', 'ember-metal/get', 'ember-metal/observer', 'ember-metal/set'], function (exports, _utils, _emberComputed, _emberHelper, _get, _observer, _set) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { defineProperty, Object: emberObject } = Ember;

  const groupFunction = function () {
    let array = (0, _get.default)(this, 'array');
    let byPath = (0, _get.default)(this, 'byPath');
    let groups = emberObject.create();

    array.forEach(item => {
      let groupName = (0, _get.default)(item, byPath);
      let group = (0, _get.default)(groups, groupName);

      if (!(0, _utils.isEmberArray)(group)) {
        group = (0, _utils.A)();
        groups[`${groupName}`] = group;
      }

      group.push(item);
    });

    return groups;
  };

  exports.default = _emberHelper.default.extend({
    compute([byPath, array]) {
      (0, _set.default)(this, 'array', array);
      (0, _set.default)(this, 'byPath', byPath);

      return (0, _get.default)(this, 'content');
    },

    byPathDidChange: (0, _observer.default)('byPath', function () {
      let byPath = (0, _get.default)(this, 'byPath');

      if (byPath) {
        defineProperty(this, 'content', (0, _emberComputed.default)(`array.@each.${byPath}`, groupFunction));
      } else {
        defineProperty(this, 'content', null);
      }
    }),

    contentDidChange: (0, _observer.default)('content', function () {
      this.recompute();
    })
  });
});