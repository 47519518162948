define('ember-composable-helpers/helpers/inc', ['exports', 'ember-helper', 'ember-utils'], function (exports, _emberHelper, _emberUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.inc = inc;
  function inc([step, val]) {
    if ((0, _emberUtils.isEmpty)(val)) {
      val = step;
      step = undefined;
    }

    val = Number(val);

    if (isNaN(val)) {
      return;
    }

    if (step === undefined) {
      step = 1;
    }

    return val + step;
  }

  exports.default = (0, _emberHelper.helper)(inc);
});