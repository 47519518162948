define('ember-composable-helpers/helpers/optional', ['exports', 'ember-helper'], function (exports, _emberHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.optional = optional;
  function optional([action]) {
    if (typeof action === 'function') {
      return action;
    }

    return i => i;
  }

  exports.default = (0, _emberHelper.helper)(optional);
});