define('ember-concurrency/-helpers', ['exports', 'ember-concurrency/-task-property'], function (exports, _taskProperty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.taskHelperClosure = taskHelperClosure;
  var get = Ember.get;
  var bind = Ember.run.bind;
  function taskHelperClosure(taskMethod, _args, hash) {
    let task = _args[0];
    let outerArgs = _args.slice(1);

    return bind(null, function (...innerArgs) {
      if (!(task instanceof _taskProperty.Task)) {
        (true && !(false) && Ember.assert(`The first argument passed to the \`perform\` helper should be a Task object (without quotes); you passed ${task}`, false));

        return;
      }

      if (hash && hash.value) {
        let event = innerArgs.pop();
        innerArgs.push(get(event, hash.value));
      }

      return task[taskMethod](...outerArgs, ...innerArgs);
    });
  }
});