define('ember-composable-helpers/helpers/shuffle', ['exports', 'ember-array/utils', 'ember-helper', 'ember-metal/observer', 'ember-metal/get', 'ember-metal/set', 'ember-utils'], function (exports, _utils, _emberHelper, _observer, _get, _set, _emberUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.shuffle = shuffle;
  function shuffle(array, randomizer) {
    array = array.slice(0);
    let count = (0, _get.default)(array, 'length');
    let rand, temp;
    randomizer = (0, _emberUtils.typeOf)(randomizer) === 'function' && randomizer || Math.random;

    while (count > 1) {
      rand = Math.floor(randomizer() * count--);

      temp = array[count];
      array[count] = array[rand];
      array[rand] = temp;
    }
    return array;
  }

  exports.default = _emberHelper.default.extend({
    compute([random, array]) {
      if (array === undefined) {
        array = random;
        random = undefined;
      }

      if (!(0, _utils.isEmberArray)(array)) {
        return (0, _utils.A)([array]);
      }

      (0, _set.default)(this, 'array', array);
      return shuffle(array, random);
    },

    arrayContentDidChange: (0, _observer.default)('array.[]', function () {
      this.recompute();
    })
  });
});