define('ember-composable-helpers/helpers/reverse', ['exports', 'ember-array/utils', 'ember-helper', 'ember-metal/observer', 'ember-metal/set'], function (exports, _utils, _emberHelper, _observer, _set) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberHelper.default.extend({
    compute([array]) {
      if (!(0, _utils.isEmberArray)(array)) {
        return [array];
      }

      (0, _set.default)(this, 'array', array);
      return (0, _utils.A)(array).slice(0).reverse();
    },

    arrayContentDidChange: (0, _observer.default)('array.[]', function () {
      this.recompute();
    })
  });
});