define('ember-composable-helpers/helpers/reduce', ['exports', 'ember-helper', 'ember-metal/get', 'ember-metal/observer', 'ember-metal/set', 'ember-utils', 'ember-computed'], function (exports, _emberHelper, _get, _observer, _set, _emberUtils, _emberComputed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { defineProperty } = Ember;

  exports.default = _emberHelper.default.extend({
    compute([callback, initialValue, array]) {
      (0, _set.default)(this, 'callback', callback);
      (0, _set.default)(this, 'array', array);
      (0, _set.default)(this, 'initialValue', initialValue);

      return (0, _get.default)(this, 'content');
    },

    callbackDidChange: (0, _observer.default)('callback', 'initialValue', function () {
      let callback = (0, _get.default)(this, 'callback');
      let initialValue = (0, _get.default)(this, 'initialValue');

      if ((0, _emberUtils.isEmpty)(callback)) {
        defineProperty(this, 'content', []);
        return;
      }

      let cp = (0, _emberComputed.default)('array.[]', () => {
        let array = (0, _get.default)(this, 'array');
        return array.reduce(callback, initialValue);
      });

      defineProperty(this, 'content', cp);
    }),

    contentDidChange: (0, _observer.default)('content', function () {
      this.recompute();
    })
  });
});