define('ember-paper/components/paper-dialog-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { Component } = Ember;

  /**
   * @class PaperDialogContainer
   * @extends Ember.Component
   */
  exports.default = Component.extend({
    classNames: ['md-dialog-container'],

    mouseDown(ev) {
      this._sourceEl = ev.target;
    },

    mouseUp(ev) {
      if (this._sourceEl === this.element && ev.target === this.element) {
        ev.stopPropagation();
        ev.preventDefault();
        this.sendAction('outsideClicked');
      }
    }
  });
});